import React, { useState } from 'react';
import './skillsCard.scss';
import TabHeader from '../TabSelector/TabHeader';
import StudyMaterialModal from '../StudyMaterialModal/StudyMaterialModal';

const studyMaterialsData = {
  attempt1: [
    { courseName: "Course 1 Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75674" },
    { courseName: "Course 2 Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75675" },
    { courseName: "Course 3 Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75937" },
    { courseName: "Course 4 Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75938" },
    { courseName: "Course 5 Name", link: "https://vlearnv.herovired.com/page-activity/301/quiz/73882" },
    { courseName: "Course 6 Name", link: "https://vlearnv.herovired.com/page-activity/301/quiz/73883" },
    { courseName: "Course 7 Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75675" },
    { courseName: "Course 8 Name", link: "https://vlearnv.herovired.com/page-activity/301/quiz/73883" },
    { courseName: "Course 9 Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75674" },
    { courseName: "Course 10 Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75938" },
  ],
  attempt2: [
    { courseName: "Course A Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75674" },
    { courseName: "Course B Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75675" },
    { courseName: "Course C Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75937" },
    { courseName: "Course D Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75938" },
    { courseName: "Course E Name", link: "https://vlearnv.herovired.com/page-activity/301/quiz/73882" },
    { courseName: "Course F Name", link: "https://vlearnv.herovired.com/page-activity/301/quiz/73883" },
    { courseName: "Course G Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75675" },
    { courseName: "Course H Name", link: "https://vlearnv.herovired.com/page-activity/301/quiz/73883" },
    { courseName: "Course I Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75674" },
    { courseName: "Course J Name", link: "https://vlearnv.herovired.com/page-activity/301/assign/75938" },
  ]
};

const skillData = {
  attempt1: [
      {
          title: 'SQL',
          score: '12',
          total: '15',
          gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
          points: [
              'Exceptional SQL skills! Mastering SQL enables you to efficiently query and manage data across databases, making you an asset for any data-driven organization.',
              'Continue exploring advanced SQL techniques like stored procedures and performance optimization.',
          ],
      },
      {
        title: 'MongoDB',
        score: '8',
        total: '15',
        gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
        points: [
            'Strong MongoDB skills! Your proficiency in this NoSQL database equips you to work with modern, scalable applications.',
            'Mastering advanced concepts like sharding and replica sets will further enhance your expertise.',
        ],
    },
    {
      title: 'Apache Cassandra',
      score: '10',
      total: '15',
      gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
      points: [
          'Your understanding of Cassandra is developing. This database is ideal for handling high-velocity data with high availability.',
          'Strengthen your skills in data modeling, clustering, and partitioning for robust database solutions.',
      ],
  },
  {
    title: 'Python',
    score: '4',
    total: '15',
    gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
    points: [
        'Python is a versatile programming language widely used in data analysis, machine learning, and web development.',
        'Strengthening your Python skills will expand your ability to solve diverse technical challenges.',
    ],
},
{
  title: 'Hadoop',
  score: '12',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Strong Hadoop skills! Your expertise in distributed computing makes you well-prepared for big data challenges.',
      'Delve into advanced topics like Hive, Pig, and Spark integration for greater impact.',
  ],
},
{
  title: 'Azure',
  score: '10',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Your Azure skills are growing.',
      'Focus on understanding core services like Azure Storage, Virtual Machines, and Azure Data Factory to harness the full potential of the platform.',
  ],
},
{
  title: 'Apache Spark',
  score: '12',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Great command of Apache Spark! Your expertise in distributed computing and real-time analytics can drive impactful insights from large datasets.',
      'Focus on integrating with tools like PySpark for advanced workflows.',
  ],
},
{
  title: 'EDA',
  score: '3',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'EDA is fundamental for uncovering patterns and relationships in data.',
      'Strengthening this skill will allow you to derive actionable insights and inform decision-making.',
  ],
},
{
  title: 'Spark Streaming',
  score: '10',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Strong Spark Streaming skills! Your expertise in real-time data processing is invaluable for applications like fraud detection and live analytics.',
      'Master integration with Kafka for robust pipelines.',
  ],
},
{
  title: 'PySpark',
  score: '5',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Your PySpark skills are developing.',
      ' Focus on learning DataFrame operations, SparkSQL, and machine learning pipelines to fully utilize this tool for big data processing.',
  ],
},
{
  title: 'Power BI',
  score: '12',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Outstanding Power BI skills! Your ability to create compelling dashboards makes you a valuable asset for decision-making processes.',
      'Explore advanced features like row-level security and Power BI service.',
  ],
},
{
  title: 'Excel',
  score: '12',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Exceptional Excel skills! Your expertise enables efficient data handling and advanced analysis.',
      'Continue leveraging Excel for complex modeling and reporting tasks.',
  ],
},
      // other skills for Attempt 1
  ],
  attempt2: [
    {
      title: 'SQL',
      score: '12',
      total: '15',
      gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
      points: [
          'Exceptional SQL skills! Mastering SQL enables you to efficiently query and manage data across databases, making you an asset for any data-driven organization.',
          'Continue exploring advanced SQL techniques like stored procedures and performance optimization.',
      ],
  },
  {
    title: 'MongoDB',
    score: '8',
    total: '15',
    gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
    points: [
        'Strong MongoDB skills! Your proficiency in this NoSQL database equips you to work with modern, scalable applications.',
        'Mastering advanced concepts like sharding and replica sets will further enhance your expertise.',
    ],
},
{
  title: 'Apache Cassandra',
  score: '10',
  total: '15',
  gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
  points: [
      'Your understanding of Cassandra is developing. This database is ideal for handling high-velocity data with high availability.',
      'Strengthen your skills in data modeling, clustering, and partitioning for robust database solutions.',
  ],
},
{
title: 'Python',
score: '4',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
    'Python is a versatile programming language widely used in data analysis, machine learning, and web development.',
    'Strengthening your Python skills will expand your ability to solve diverse technical challenges.',
],
},
{
title: 'Hadoop',
score: '12',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'Strong Hadoop skills! Your expertise in distributed computing makes you well-prepared for big data challenges.',
  'Delve into advanced topics like Hive, Pig, and Spark integration for greater impact.',
],
},
{
title: 'Azure',
score: '10',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'Your Azure skills are growing.',
  'Focus on understanding core services like Azure Storage, Virtual Machines, and Azure Data Factory to harness the full potential of the platform.',
],
},
{
title: 'Apache Spark',
score: '12',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'Great command of Apache Spark! Your expertise in distributed computing and real-time analytics can drive impactful insights from large datasets.',
  'Focus on integrating with tools like PySpark for advanced workflows.',
],
},
{
title: 'EDA',
score: '3',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'EDA is fundamental for uncovering patterns and relationships in data.',
  'Strengthening this skill will allow you to derive actionable insights and inform decision-making.',
],
},
{
title: 'Spark Streaming',
score: '12',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'Strong Spark Streaming skills! Your expertise in real-time data processing is invaluable for applications like fraud detection and live analytics.',
  'Master integration with Kafka for robust pipelines.',
],
},
{
title: 'PySpark',
score: '5',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'Your PySpark skills are developing.',
  ' Focus on learning DataFrame operations, SparkSQL, and machine learning pipelines to fully utilize this tool for big data processing.',
],
},
{
title: 'Power BI',
score: '12',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'Outstanding Power BI skills! Your ability to create compelling dashboards makes you a valuable asset for decision-making processes.',
  'Explore advanced features like row-level security and Power BI service.',
],
},
{
title: 'Excel',
score: '12',
total: '15',
gradient: 'linear-gradient(to right, #d4fc79, #96e6a1)',
points: [
  'Exceptional Excel skills! Your expertise enables efficient data handling and advanced analysis.',
  'Continue leveraging Excel for complex modeling and reporting tasks.',
],
},
  ]
};
function SkillsCard({ isAttempt1 }) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const currentSkills = isAttempt1 ? skillData.attempt1 : skillData.attempt2;
  const attemptKey = isAttempt1 ? 'attempt1' : 'attempt2';
  // const handleViewMaterialClick = () => {
  //   setModalContent(studyMaterialsData[attemptKey]);
  //   setModalVisible(true);
  // };

  const linksPerSkill = Math.ceil(
    studyMaterialsData[attemptKey].length / currentSkills.length
  );

  const handleViewMaterialClick = (skillIndex) => {
    const start = skillIndex * linksPerSkill;
    const end = start + linksPerSkill;
    const skillLinks = studyMaterialsData[attemptKey].slice(start, end);
    setModalContent(skillLinks);
    setModalVisible(true);
  };

  const getGradient = (percentage) => {
    if (percentage >= 80) {
      return 'var(--prt-skill-test-good)';
    } else if (percentage >= 60) {
      return 'var(--prt-skill-test-average)';
    } else {
      return 'var(--prt-skill-test-bad)';
    }
  };

  const closeModal = () => setModalVisible(false);
  return (
   <div className="skill-card">
        {currentSkills.map((skill, index) => {
        const percentage = (parseInt(skill.score) / parseInt(skill.total)) * 100;
        const gradient = getGradient(percentage);
        return (
          <div key={index} className="skill-card-content">
            <TabHeader
              title={skill.title}
              background={gradient}
              cta={
                <span>
                  <span className="cta-numerator">{skill.score}</span> /
                  <span className="cta-denominator">{skill.total}</span>
                </span>
              }
            />
            <div className="skill-points">
              <ul>
                {skill.points.map((point, idx) => (
                  <div key={idx} className="skill-content">
                    {point}
                  </div>
                ))}
              </ul>
              <div
                className="skill-points__title"
                onClick={() => handleViewMaterialClick(index)}
              >
                View Study Material
              </div>
            </div>
          </div>
        );
      })}
       <StudyMaterialModal
        isVisible={isModalVisible}
        onClose={closeModal}
        studyMaterials={modalContent}
        // title={`Study Material for ${isAttempt1 ? "Attempt 1" : "Attempt 2"}`}
      />
   </div>
  )
}

export default SkillsCard